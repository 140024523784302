import React from "react";
import sections from "../../constants/data";
import images from "../../constants/images";
import "./Qualities.css";

const Qualities = () => {
  return (
    <section className="qualities section-p bg-light-grey" id="qualities">
      <div className="container">
        <div className="qualities-content grid">
          <div className="content-left">
            <div className="section-t text-center">
              <h3>We are Professionals</h3>
              <p className="text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
                voluptates deserunt, molestiae soluta cumque eum.
              </p>
            </div>

            <div className="item-list grid text-white">
              {sections.qualities.map((quality) => {
                return (
                  <div className="flex" key={quality.id}>
                    <div className="item-icon">{quality.icon}</div>
                    <div>
                      <h3 className="item-title text-dark fs-25">Ideas & Plans</h3>
                      <p className="text">{quality.text}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            <img src={images.professional_img} alt="run treadmill" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualities;
