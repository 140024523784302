import React from "react";
import "./Contact.css";

const Map = () => {
  return (
    <div className="map-content">
      {/* eslint-disable-next-line */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158858.47340035567!2d0.038483985638878934!3d51.528558241554826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2z15zXldeg15PXldefLCDXkdeo15nXmNeg15nXlA!5e0!3m2!1siw!2sil!4v1679294851866!5m2!1siw!2sil"
        width="100%"
        height="450"
        style={{ border: 0 }}
      ></iframe>
    </div>
  );
};

export default Map;
