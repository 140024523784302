import React, { useState } from "react";
import "./Navbar.css";
import { FaTwitter, FaFacebookF, FaInstagram } from "react-icons/fa";
import { IoMdRocket } from "react-icons/io";

const Navbar = () => {
  const [navToggle, setNavToggle] = useState(false);
  const navHandler = () => {
    setNavToggle((prevData) => !prevData);
  };

  return (
    <nav className="navbar w-100 flex">
      <div className="container w-100">
        <div className="navbar-content flex fw-7">
          <div className="brand-and-toggler flex flex-between w-100">
            <a href="#header" className="navbar-brand fs-26">
              Fitness.
            </a>
            <div
              type="button"
              className={`hamburger-menu ${
                navToggle ? "hamburger-menu-change" : ""
              }`}
              onClick={navHandler}
            >
              <div className="bar-top"></div>
              <div className="bar-middle"></div>
              <div className="bar-bottom"></div>
            </div>
          </div>

          <div
            className={`navbar-collapse ${
              navToggle ? "show-navbar-collapse" : ""
            }`}
          >
            <div className="navbar-collapse-content text-light-grey">
              <ul className="navbar-nav">
                <li className="">
                  <a href="#about">About</a>
                </li>
                <li className="">
                  <a href="#features">Features</a>
                </li>
                <li className="">
                  <a href="#testimonials">Testimonials</a>
                </li>
                <li className="">
                  <a href="#contact">Contacts</a>
                </li>
              </ul>
              <ul className="navbar-social flex">
                <li className="instagram">
                  <a
                    href="https://www.instagram.com/"
                    className="flex flex-center"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li className="twitter">
                  <a
                    href="https://www.twitter.com/"
                    className="flex flex-center"
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li className="facebook">
                  <a
                    href="https://www.facebook.com/"
                    className="flex flex-center"
                  >
                    <FaFacebookF />
                  </a>
                </li>
              </ul>
              <div className="navbar-btns">
                <button type="button" className="btn">
                  <IoMdRocket className="" />{" "}
                  <span className="">get started</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
