import React from "react";
import Navbar from "../Navbar/Navbar";
import "./Header.css";

const Header = () => {
  return (
    <header className="header flex flex-center flex-column" id="header">
      <Navbar />
      <div className="container">
        <div className="header-content text-center flex flex-column">
          <h1 className="text-uppercase header-title">
            right fitness right time
          </h1>
          <p className="text-lead">
            We provide fitness experience like no other. An inspired space that
            transporting you to an electric gym where unexpected design elements
            and top-of-the-line equipments are waiting around every corner. Just for you.
          </p>
          <a href="/" className="btn header-btn btn-blue">
            🚀<span> Let's go</span>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
