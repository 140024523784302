import React from "react";
import "./Contact.css";
import images from "../../constants/images";
import Info from "./Info";
import Map from "./Map";

const Contact = () => {
  return (
    <section className="contact section-p-top bg-light-grey" id="contact">
      <div className="container">
        <div className="contact-content grid text-center">
          <div className="contact-left">
            <div className="section-t">
              <h3>Let's Talk?</h3>
              <p className="text">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sit,
                assumenda quia repellendus architecto culpa nisi?
              </p>
            </div>

            <form className="form-elem">
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="form-control"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="form-control"
              />
              <input
                type="number"
                name="phone"
                placeholder="Phone number"
                className="form-control"
              />

              <div className="flex flex-start">
                <button type="submit" className="submit-btn">
                  send
                </button>
              </div>
            </form>
          </div>

          <div className="contact-right">
            <img src={images.contact_img} alt="leg press" />
          </div>
        </div>
      </div>

      <Map />
      <Info />
    </section>
  );
};

export default Contact;
