import about_img from "../assets/fitness-images/about-img.jpg";
import professional_img from "../assets/fitness-images/professional_img.jpg";
import customer_img_1 from "../assets/fitness-images/customer_img_1.jpg";
import customer_img_2 from "../assets/fitness-images/customer_img_2.jpg";
import customer_img_3 from "../assets/fitness-images/customer_img_3.jpg";
import customer_img_4 from "../assets/fitness-images/customer_img_4.jpg";
import customer_img_5 from "../assets/fitness-images/customer_img_5.jpg";
import customer_img_6 from "../assets/fitness-images/customer_img_6.jpg";
import contact_img from "../assets/fitness-images/pic-3.jpg";
import picture_1 from "../assets/fitness-images/picture-1.jpg";
import picture_2 from "../assets/fitness-images/picture-4.jpg";
import picture_3 from "../assets/fitness-images/picture-6.jpg";
import picture_4 from "../assets/fitness-images/picture-3.jpeg";

const images = {
  professional_img,
  picture_4,
  customer_img_1,
  customer_img_2,
  customer_img_3,
  customer_img_4,
  customer_img_5,
  customer_img_6,
  about_img,
  contact_img,
  picture_1,
  picture_2,
  picture_3,
};

export default images;
